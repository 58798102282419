import * as React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const SchoolsFestival2021 = (props) => {
	// const { showEventsLink } = props

	const data = useStaticQuery(graphql`{
  schoolsFestival: file(relativePath: {eq: "schools-festival.md"}) {
    childMarkdownRemark {
      html
      frontmatter {
        id
        title
      }
    }
  }
  programmeChildrensImg: file(
    relativePath: {eq: "Boswell-Schools-and-Family-Online-2021-Cover-001.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
    }
  }
}
`)

	return (
		<section id="/about" className="p-4 md:p-12">
			<div className={`flex flex-row items-start flex-wrap`}>
				<div className={`md:w-1/2`}>
					<div className="markdown-content" dangerouslySetInnerHTML={{ __html: data.schoolsFestival.childMarkdownRemark.html }} />
					{props.showEventsLink && (
						<div className={`pt-4 md:pt-12`}>
							<Link className={`link text-2xl`} to={`/2021/childrens-festival-events`}>2021 Children's Festival Events</Link>
						</div>
					)}
				</div>
				<div className={`p-4 md:p-12 md:w-1/2`}>
					<a
						className={`mx-auto`}
						href={`/Boswell Schools and Family 2021 Online Programme 001.pdf`}
						title={`Download the programme for the Boswell Schools & Family Festival 2021`}>
						<GatsbyImage
							image={data.programmeChildrensImg.childImageSharp.gatsbyImageData}
							alt={`Boswell Schools and Family 2021 Programme`}
							objectFit={`cover`}
							className={`w-24 sm:w-32 lg:w-40 xl:w-48 2xl:w-64 shadow-md transform hover:scale-105 hover:shadow-lg transition-all`} />
					</a>
				</div>
			</div>
		</section>
    );
}

export default SchoolsFestival2021
