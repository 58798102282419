import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
// import Tippy from '@tippyjs/react'
// import EventDayOverlay from "./EventDayOverlay"

const EventList = (props) => {

	const { data } = props

	// const renderBook = (title) => {
	// 	if (title && title.length > 0) {
	// 		return <p className={`text-bbf-dark-green`}>{title}</p>
	// 	}
	// }
	//
	// const renderOnStageWith = (onStageWith) => {
	// 	if (onStageWith && onStageWith.length > 0) {
	// 		return <p className={`text-bbf-dark-green`}>{onStageWith}</p>
	// 	}
	// }
	//
	// const renderInterviewer = (interviewer) => {
	// 	if (interviewer && interviewer.length > 0) {
	// 		return <p className={`text-bbf-dark-green`}>{interviewer}</p>
	// 	}
	// }

	const EventItem = (props) => {
		const { data } = props

		return (
            <div className={`shadow-md bg-white flex flex-col items-center`}>
				<Link to={`/2021/events/${data.event_id}`} className={`block w-full`}>
					<div className={`w-full h-48 relative`}>
						<GatsbyImage
							image={data.event_image.childImageSharp.gatsbyImageData}
							objectFit={`cover`}
							className={`h-48`}
							alt={data.event_title} />
						{/* Commenting the day overlay out for 2021 */}
						{/*<EventDayOverlay date={data.event_start} />*/}
						{/*<img src={`/img/events/2021/${}`} alt={`${data.author_firstname} ${data.author_lastname}`} />*/}
					</div>
					<div className={`p-2`}>
						<h2 className={`mb-2 text-lg font-heading leading-tight text-bbf-dark-green pb-1 border-b-2 border-bbf-mid-green-alt`}>{data.event_title}</h2>
						<p className={`text-sm`}>{data.event_excerpt}</p>
						{/*{renderBook(data.book_title)}*/}
						{/*{renderOnStageWith(data.event_on_stage_with)}*/}
						{/*{renderInterviewer(data.event_interviewer_name)}*/}
					</div>
				</Link>
				{/*<div className={`p-2 mt-auto w-full bg-gray-100 flex items-center justify-around`}>*/}
				{/*	<Tippy*/}
				{/*		content={(*/}
				{/*			<div className={`text-center`}>*/}
				{/*				<p>All 2021 festival events are free.</p>*/}
				{/*				<p><Link to={`/donate`} className={`link text-white`}>Make a donation to show your support.</Link></p>*/}
				{/*			</div>*/}
				{/*		)}*/}
				{/*		animation="perspective"*/}
				{/*		placement="top"*/}
				{/*		interactive="true"*/}
				{/*		arrow={true}*/}
				{/*		inertia={true}*/}
				{/*	>*/}
				{/*		<Link to={`/2021/box-office`} state={{ eventId: data.event_ft_id }} title={`Register for: ${data.event_title}`}>Register</Link>*/}
				{/*	</Tippy>*/}
				{/*	{ data.book_1_title && data.book_1_title.length && (*/}
				{/*		<Tippy*/}
				{/*			content={(*/}
				{/*				<p className={`text-center`}>Buy the book '{data.book_1_title}' by {data.author_1_name}</p>*/}
				{/*			)}*/}
				{/*			animation="perspective"*/}
				{/*			placement="top"*/}
				{/*			interactive="true"*/}
				{/*			arrow={true}*/}
				{/*			inertia={true}*/}
				{/*		>*/}
				{/*			<a href={data.book_1_shop_link} title={`Buy the book '${data.book_1_title}' by ${data.author_1_name}`} target={`_blank`} rel={`nofollow noopener`}>Buy the book</a>*/}
				{/*		</Tippy>*/}
				{/*	)}*/}
				{/*</div>*/}
			</div>
        );
	}

	return (
		<div className={`grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4`}>
			{data.map((item, index) => {
				return <EventItem data={item.node.frontmatter} key={index} />
			})}
		</div>
	)
}

export default EventList
