import * as React from 'react'
import { graphql } from 'gatsby'
// import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../../components/Layout/Layout'
import BannerImage from '../../components/Page/BannerImage'
import EventList from '../../components/Event/EventList'
import SchoolsFestival2021 from '../../components/Page/SchoolsFestival2021'

const events = ({data}) => {

	const events = data.eventPages.edges

	return (
		<Layout>
			<BannerImage image={data.bannerImage.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className={`p-4 md:p-12`}>
					<div className={`flex items-center justify-between mb-6`}>
						<h2 className={`text-3xl mb-6`}>2021 Boswell Book Festival Events</h2>
					</div>
					<EventList data={events} />
				</div>
				<SchoolsFestival2021 showEventsLink={true}/>

				{/*<div className={`flex flex-row items-start flex-wrap`}>*/}
				{/*	<div className={`md:w-1/2`}>*/}
				{/*	</div>*/}
				{/*	<div className={`p-4 md:p-12 md:w-1/2`}>*/}
				{/*		<a*/}
				{/*			className={`mx-auto`}*/}
				{/*			href={`/Boswell Schools and Family 2021 Online Programme 001.pdf`}*/}
				{/*			title={`Download the programme for the Boswell Schools & Family Festival 2021`}>*/}
				{/*			<Img*/}
				{/*				objectFit={`cover`}*/}
				{/*				className={`w-24 sm:w-32 lg:w-40 xl:w-48 2xl:w-64 shadow-md transform hover:scale-105 hover:shadow-lg transition-all`}*/}
				{/*				fluid={data.programmeChildrensImg.childImageSharp.fluid} />*/}
				{/*		</a>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</section>
		</Layout>
	)
}

export const query = graphql`{
  bannerImage: file(relativePath: {eq: "general/Bozzy-16-9-001.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
  eventPages: allMarkdownRemark(
    filter: {frontmatter: {category: {eq: "event"}, event_type: {eq: "main"}, event_visible: {eq: true}}}
    sort: {order: ASC, fields: [frontmatter___event_id]}
  ) {
    edges {
      node {
        frontmatter {
          author_1_image_credit
          author_1_name
          book_1_shop_link
          book_1_title
          event_title
          event_id
          event_image {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
          }
          event_date
          event_end
          event_excerpt
          event_ft_id
          event_start
          event_type
        }
      }
    }
  }
}
`

export default events
